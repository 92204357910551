import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import {
  clearJobList,
  getAshleyAwsRecommendedJobs,
  getAshleyRecommendedJobs,
  getRecentlyViewedJobs,
  updateJobListPage,
} from "../../../redux/actions/job_action";
import {
  updateShowSignInModalStatus,
  updateSignInModalSignUpStatus,
  updateSignUpOrigin,
} from "../../../redux/actions/navbar_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import HomepageSearchBar from "../../homepage/HomepageSearch/HomepageSearchBar/HomepageSearchBar";
import HomepageTrending from "../../homepage/HomepageSearch/HomepageTrending/HomepageTrending";
import AccordionJobCard from "../AccordionJobCard/AccordionJobCard";
import OldAccordionJobcard from "../AccordionJobCard/deprecate-soon/OldAccordionJobCard";
import { AiSparklesIcon, AiSummaryButton } from "../AccordionJobCard/styles";
import {
  AiArrowImage,
  AiFileImage,
  AiFileImageBox,
  AiSummaryEmptyBox,
  AiSummaryEmptyText,
  AiSummaryWrapper,
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  EmptyContainer,
  IconButtonStyled,
  JobListContainer,
  PageNumber,
  PaginationNumberBox,
} from "../AccordionJobList/styles";
import AiJobSummary from "../AiJobSummary/AiJobSummary";
import OldAiJobSummary from "../AiJobSummary/deprecate-soon/OldAiJobSummary";
import {
  AccordionJobListSkeleton,
  AccordionMobileJobListSkeleton,
  AiJobSummaryEmptySkeleton,
} from "../Skeleton/Skeleton";
import ResumeDropzone from "./ResumeDropzone/ResumeDropzone";
import {
  BoxContainer,
  ButtonStyled,
  ForYouWrapper,
  LockIcon,
  PlaceholderSubtitle,
  PlaceholderTitle,
  PlaceholderWrapper,
  SubheadingText,
  VisitorCard,
  VisitorSubtitle,
  VisitorTitle,
  VisitorWrapper,
  Wrapper,
} from "./styles";

function AccordionForYou(props) {
  const {
    isTabLoading,
    isLoggedIn,
    loadingJobs,
    onFollowJobClicked,
    onApplyJobClicked,
    unSaveJob,
    currentId,
  } = props;
  const dispatch = useDispatch();

  const userLoggedIn = checkAuthentication();

  useEffect(() => {
    dispatch(clearJobList());
  }, []);

  return (
    <Wrapper>
      {isLoggedIn && userLoggedIn ? (
        <ForYouJobList
          isTabLoading={isTabLoading}
          loadingJobs={loadingJobs}
          onFollowJobClicked={onFollowJobClicked}
          onApplyJobClicked={onApplyJobClicked}
          unSaveJob={unSaveJob}
          currentId={currentId}
        />
      ) : (
        <VisitorPlaceholder />
      )}
    </Wrapper>
  );
}

function ForYouJobList(props) {
  const {
    isTabLoading,
    onFollowJobClicked,
    onApplyJobClicked,
    unSaveJob,
    currentId,
  } = props;
  const dispatch = useDispatch();

  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const isMobile = useMediaQuery("(max-width: 640px)");

  const user = useSelector((state) => state.user.user);
  const jobs = useSelector((state) => state.jobs.jobs);
  const fypTab = Number(useSelector((state) => state.jobs.fypTab));
  const viewHistoryState = useSelector((state) => state.jobs.viewHistoryState);
  const fetchingForYou = useSelector((state) => state.jobs.fetchingForYou);
  const fetchingJobs = useSelector((state) => state.jobs.fetchingJobList);
  const jobPageInfo = useSelector((state) => state.jobs.jobPageInfoV1);
  const currentPage = useSelector((state) => state.jobs.jobCurrentPage);

  const [showAiSummary, setShowAiSummary] = useState(false);
  const [jobIndex, setJobIndex] = useState("");

  const inputTime = dayjs(user?.uploadResumeAt);
  const timePlusFiveMinutes = inputTime.add(5, "minute");
  const currentTime = dayjs();
  const resumeValidTime = timePlusFiveMinutes.isAfter(currentTime);

  const resumeValid =
    (!resumeValidTime || user?.state === "approved") &&
    user?.state === "approved";

  const resumeExist = user?.resume?.length > 1;
  const activityExist = useSelector((state) => state.jobs.activityExist);
  const historyExist = useSelector((state) => state.jobs.historyExist);

  const jobCardVariant = useFeatureFlagVariantKey("new-hover-card-ai-summary");

  const subHeading = viewHistoryState
    ? "Check out your recently viewed jobs and easily revisit them:"
    : fypTab === 1
    ? "Here are handpicked roles based on your resume:"
    : fypTab === 2
    ? "Here are job matches based on your activity (viewed, saved & applied jobs):"
    : "";

  // check if user has met the requirements
  const jobHistoryInvalid =
    !fetchingForYou &&
    viewHistoryState &&
    (!historyExist || jobs?.length === 0);

  const profileBasedInvalid =
    fypTab === 1 &&
    (!resumeExist || !resumeValid || jobs?.length === 0) &&
    !viewHistoryState;

  const activityBasedInvalid =
    fypTab === 2 && (!activityExist || jobs?.length === 0) && !viewHistoryState;

  const scrollToComponent = (index) => {
    const componentRef = document.getElementById(index);
    var headerOffset = 155;
    var elementPosition = componentRef.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  function handleNextPage() {
    scrollTo(0, 0);

    const jobKey = new Date().getTime();

    const params = {
      currentLoadingJobsKey: jobKey,
      first: 30,
      last: null,
      startCursor: null,
      endCursor: jobPageInfo?.endCursor,
      refresh: fypTab === 1 ? true : false,
    };

    if (viewHistoryState) {
      return dispatch(updateJobListPage(currentPage + 1)).then(() => {
        dispatch(getRecentlyViewedJobs(params));
      });
    }

    switch (fypTab) {
      case 1: {
        dispatch(updateJobListPage(currentPage + 1)).then(() => {
          dispatch(getAshleyRecommendedJobs(params));
        });
        break;
      }
      case 2: {
        dispatch(updateJobListPage(currentPage + 1)).then(() => {
          dispatch(getAshleyAwsRecommendedJobs(params));
        });
        break;
      }
      default:
        break;
    }
  }

  function handlePreviousPage() {
    scrollTo(0, 0);

    const jobKey = new Date().getTime();

    const params = {
      currentLoadingJobsKey: jobKey,
      first: null,
      last: 30,
      startCursor: jobPageInfo?.startCursor,
      endCursor: null,
      refresh: fypTab === 1 ? true : false,
    };

    if (viewHistoryState) {
      return dispatch(updateJobListPage(currentPage + 1)).then(() => {
        dispatch(getRecentlyViewedJobs(params));
      });
    }

    switch (fypTab) {
      case 1: {
        dispatch(updateJobListPage(currentPage - 1)).then(() => {
          dispatch(getAshleyRecommendedJobs(params));
        });
        break;
      }
      case 2: {
        dispatch(getAshleyAwsRecommendedJobs(params)).then(() => {
          dispatch(updateJobListPage(currentPage - 1));
        });
        break;
      }
      default:
        break;
    }
  }

  useEffect(() => {
    if ((!(resumeExist || activityExist) && !fetchingForYou) || isTabLoading) {
      return;
    }

    dispatch(updateJobListPage(1));
    const jobKey = new Date().getTime();
    const params = {
      currentLoadingJobsKey: jobKey,
      first: 30,
      last: null,
      startCursor: null,
      endCursor: null,
      refresh: fypTab === 1 ? true : false,
    };

    switch (fypTab) {
      case 1: {
        dispatch(getAshleyRecommendedJobs(params));
        break;
      }
      case 2: {
        dispatch(getAshleyAwsRecommendedJobs(params));
        break;
      }
      default:
        break;
    }
  }, [dispatch]);

  return fetchingJobs || fetchingForYou ? (
    <ForYouWrapper>
      <JobListContainer>
        {Array.from({ length: 10 }).map((_, index) => {
          return isMobile ? (
            <AccordionMobileJobListSkeleton key={index} />
          ) : (
            <AccordionJobListSkeleton key={index} />
          );
        })}
      </JobListContainer>
      {jobCardVariant === "hover-job-card" ? (
        (fetchingJobs || fetchingForYou) && isDesktop ? (
          <AiJobSummary />
        ) : null
      ) : fetchingJobs || fetchingForYou ? (
        <AiJobSummaryEmptySkeleton />
      ) : null}
    </ForYouWrapper>
  ) : jobHistoryInvalid ? (
    <HistoryListPlaceholder />
  ) : profileBasedInvalid ? (
    <ProfileBasedPlaceholder />
  ) : activityBasedInvalid ? (
    <ActivityBasedPlaceholder />
  ) : (
    <ForYouWrapper>
      <JobListContainer>
        <SubheadingText>{subHeading}</SubheadingText>
        <>
          {jobs.map((job, index) => {
            return jobCardVariant === "hover-job-card" ? (
              <AccordionJobCard
                key={index}
                job={job}
                index={index}
                onFollowJobClicked={onFollowJobClicked}
                onApplyJobClicked={onApplyJobClicked}
                unSaveJob={unSaveJob}
                currentId={currentId}
                origin={"for-you-page"}
              />
            ) : (
              <OldAccordionJobcard
                id={index}
                job={job}
                index={index}
                onFollowJobClicked={onFollowJobClicked}
                onApplyJobClicked={onApplyJobClicked}
                unSaveJob={unSaveJob}
                currentId={currentId}
                setShowAiSummary={setShowAiSummary}
                showAiSummary={showAiSummary}
                setJobIndex={setJobIndex}
                origin={"for-you-page"}
              />
            );
          })}

          {fetchingJobs
            ? null
            : jobs?.length > 0 && (
                <EmptyContainer
                  sx={{ width: "100%", marginTop: "25px" }}
                  container
                  direction="row"
                  justifyContent="center"
                >
                  {jobPageInfo?.hasPreviousPage ? (
                    <Fragment>
                      <IconButtonStyled
                        $margin_right="true"
                        $pagination="true"
                        disableRipple
                        onClick={() => handlePreviousPage()}
                        disabled={!jobPageInfo?.hasPreviousPage}
                      >
                        <ChevronLeftIconStyled />
                      </IconButtonStyled>
                      <PaginationNumberBox $non_focus="true">
                        <PageNumber variant="subtitle2">
                          {currentPage - 1}
                        </PageNumber>
                      </PaginationNumberBox>
                    </Fragment>
                  ) : null}

                  <PaginationNumberBox $margin="true">
                    <PageNumber variant="subtitle2">{currentPage}</PageNumber>
                  </PaginationNumberBox>

                  {jobPageInfo?.hasNextPage && (
                    <Fragment>
                      <PaginationNumberBox $non_focus="true">
                        <PageNumber variant="subtitle2">
                          {currentPage + 1}
                        </PageNumber>
                      </PaginationNumberBox>
                      <IconButtonStyled
                        $margin_left="true"
                        $pagination="true"
                        disableRipple
                        onClick={() => handleNextPage()}
                        disabled={!jobPageInfo?.hasNextPage}
                      >
                        <ChevronRightIconStyled />
                      </IconButtonStyled>
                    </Fragment>
                  )}
                </EmptyContainer>
              )}
        </>
      </JobListContainer>
      {jobCardVariant === "hover-job-card" ? (
        isDesktop ? (
          <AiJobSummary />
        ) : null
      ) : showAiSummary ? (
        <OldAiJobSummary
          setShowAiSummary={setShowAiSummary}
          showAiSummary={showAiSummary}
          onApplyJobClicked={onApplyJobClicked}
          jobIndex={jobIndex}
          scrollToComponent={scrollToComponent}
        />
      ) : (
        jobs?.length > 0 && (
          <AiSummaryWrapper>
            <AiFileImageBox>
              <AiFileImage
                src={config.assetDomain + "/images/hiredly/ai-files.svg"}
                alt="ai files"
              />
            </AiFileImageBox>
            <AiSummaryEmptyBox>
              <AiSummaryEmptyText>Click</AiSummaryEmptyText>
              <AiSummaryButton>
                Job Summary <AiSparklesIcon />
              </AiSummaryButton>
              <AiSummaryEmptyText>to see job overview.</AiSummaryEmptyText>

              <AiArrowImage
                src={config.assetDomain + "/images/hiredly/ai-arrow.svg"}
                alt="arrow"
              />
            </AiSummaryEmptyBox>
          </AiSummaryWrapper>
        )
      )}
    </ForYouWrapper>
  );
}

function VisitorPlaceholder() {
  const dispatch = useDispatch();

  // TODO: Add posthog tracking if shyang ask me to do it

  function handleTriggerLogin() {
    dispatch(updateSignUpOrigin("job_recommendation")).then(() => {
      dispatch(updateShowSignInModalStatus(true));
    });
  }

  function handleTriggerSignup() {
    dispatch(updateSignUpOrigin("job_recommendation")).then(() => {
      dispatch(updateShowSignInModalStatus(true)).then(() => {
        dispatch(updateSignInModalSignUpStatus(true));
      });
    });
  }

  return (
    <VisitorWrapper>
      <VisitorCard>
        <LockIcon />
        <BoxContainer
          flexDirection={"column"}
          alignItems={"center"}
          margin={"2rem 0 0"}
        >
          <VisitorTitle>Don't just search for jobs, get matched!</VisitorTitle>
          <VisitorSubtitle>
            Sign up now for personalised recommendations
          </VisitorSubtitle>
        </BoxContainer>
        <BoxContainer gap={"1rem"} margin={"5rem 0 0"}>
          <ButtonStyled onClick={handleTriggerLogin}>Log In</ButtonStyled>
          <ButtonStyled variant={"filled"} onClick={handleTriggerSignup}>
            Sign Up
          </ButtonStyled>
        </BoxContainer>
      </VisitorCard>
    </VisitorWrapper>
  );
}

function ProfileBasedPlaceholder(props) {
  const user = useSelector((state) => state.user?.user);
  const resumeExist = user?.resume?.length > 1;

  return (
    <PlaceholderWrapper>
      {resumeExist ? null : (
        <BoxContainer flexDirection={"column"} alignItems={"center"}>
          <PlaceholderTitle>
            We’re working on your personalised job feed
          </PlaceholderTitle>
          <PlaceholderSubtitle>
            Upload your resume and we’ll find your best matches!
          </PlaceholderSubtitle>
        </BoxContainer>
      )}

      <ResumeDropzone />
    </PlaceholderWrapper>
  );
}

function ActivityBasedPlaceholder(props) {
  const {} = props;

  return (
    <PlaceholderWrapper>
      <BoxContainer flexDirection={"column"} alignItems={"center"}>
        <PlaceholderTitle>
          We’re working on your personalised job feed
        </PlaceholderTitle>
        <PlaceholderSubtitle>
          Start searching for jobs and we’ll personalise based on your activity.
        </PlaceholderSubtitle>
      </BoxContainer>

      <BoxContainer flexDirection={"column"} alignItems={"center"}>
        <HomepageSearchBar />
        <HomepageTrending />
      </BoxContainer>
    </PlaceholderWrapper>
  );
}

function HistoryListPlaceholder(props) {
  const {} = props;

  return (
    <PlaceholderWrapper>
      <PlaceholderTitle>
        Your view history is currently empty - start searching now!
      </PlaceholderTitle>

      <BoxContainer flexDirection={"column"} alignItems={"center"}>
        <HomepageSearchBar />
        <HomepageTrending />
      </BoxContainer>
    </PlaceholderWrapper>
  );
}

export default AccordionForYou;
